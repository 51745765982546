/* Container for the carousel, which hides overflow to ensure slides outside the view aren't shown */
.carouselContainer {
    width:100%;
    height:100%;
    position: relative;
    overflow: hidden;
    
  }

/*.carouselContainer:hover .arrow{
    visibility: visible;
  }*/
  
  /* Styles for the control section which includes the arrows and the slide indicator dots */
  .controls {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 10px;
    width: 100%;
  }
  
  /* Styles for the navigation arrows */
  .arrow {
    visibility: hidden;
    cursor: pointer; /* Changes the cursor to pointer to indicate it's clickable */
    margin: 0 10px;
    font-size: 24px; /* Makes the arrows larger */
    user-select: none; /* Prevents text selection */
    position: absolute;
    top:50%;
  }

  .arrowRight{
    right:0;
    top:50%;
  }

  .arrowLeft{
    left:0;
   
  }
  
  /* Container for the slide indicators */
  .indicators {
    display: flex;
    align-items: center;
  }
  
  /* Styles for individual indicator dots */
  .indicator {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #FECFC4; /* Default color */
    margin: 0 5px;
    cursor: pointer;
    transition: width 0.3s ease; /* Smooth transition for width change */
  }
  
  /* Styles for the active indicator dot */
  .indicator.active {
    width: 20px; /* Makes the active dot wider */
    border-radius: 10px; /* Adjusts the border-radius to make it a rounded rectangle */
    background-color: #fbb9aa; /* Changes the color to indicate the active slide */
  }