.mainContainer{
    display:flex;
    flex-direction: column;
    gap:5px;
    align-items: flex-start;
}

.label{
    font-size: var(--ta-label-xs-font-size);
    color: #4F5762;
}

.input{
    border: 1px var(--ta-grey-medium) solid;
    border-radius: 5px;
    padding-left: 10px;
}
.error{
    color: red;
    font-size: 12px;
    margin-top: 5px;

}
.input:focus{
    border: 1px red solid;
}

