.reset_container{
    width:60%;
    display: flex;
    padding:0 20px 20px 20px;  
    box-sizing: border-box;
    flex-direction: column;
    justify-content: center;
    
}
.formContainer{
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.heading{
    display: flex;
    align-items: center;
    gap: 5px;
    margin-left: -15px;
}
.forgetPasswordFields{
    display: flex;
    flex-direction: column;
    gap: 15px;
    
}
.text{
    display: flex;
    flex-direction: column;
    align-items:flex-start;
    gap: 10px;
    font-size: 1rem;
}
.reset_password_container{
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.loader{
    width: 30px;
    height: 30px;
    margin-left: 20px;
}
.container{
    display: flex;
    gap: 15px;
    border: 2px solid  #2E7D32;
    border-radius: 10px;
    padding: 20px;
   /* margin-block: 50px; */
}
.back_to_login{
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 10px;
}
