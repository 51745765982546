.mainContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}



.imageContainer{
   
    display: flex;
    justify-content: center;
    align-items: center;
}

.image{
    width: 400px;
    height: 400px;
    margin-bottom: 40px;

}

.textContainer{
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 20px;
    align-items: center;
    flex-direction: column;
}

.title{
    font-size:25px;
    font-weight: 200;
    color:black;
}
.description{
    font-size: 20px;
    width:350px;
    text-wrap: wrap;
    font-weight: 200;
    color:#6153BD;
}
