:root {
/*Theme colors*/

  --ta-blue:#115599;
  --ta-blue-light-very:#d4e5f4;
  
  --ta-grey:#656F7D;
  --ta-grey-medium:#a5aab3;
  --ta-grey-dark-light:rgb( 214, 217, 222);
  --ta-grey-light:#e0e0e0;
  --ta-grey-light-very:rgb(244, 245, 247);
  --ta-grey-light-extreme:rgb(249, 249, 249);
  
  --ta-black:rgb(42, 46, 52);


  

  /* Colors*/
  --ta-primary-color: var(--ta-blue);
  --ta-primary-color-light-very:var(--ta-blue-light-very);
  --ta-background-color: var(--ta-grey-light-very);
  --ta-background-color-dark: var(--ta-grey-light);
  --ta-text-color: var(--ta-black);
  --ta-text-color-light: var(--ta-grey);
  --ta-textbox-outline-color: var(--ta-grey-medium);
  --ta-helpertext-color:var(--ta-grey-medium);

  /*Paddings*/
  --ta-padding-large:30px;
  --ta-padding-medium:20px;
  --ta-padding-small:10px;
  --ta-padding-small-very:5px;

  /*Elements*/
  --ta-label-xs-font-size:12px;

  /* Fonts */
  --ta-font-family: -apple-system, "BlinkMacSystemFont", "Segoe UI", "Helvetica", "Apple Color Emoji", "Arial", sans-serif, "Segoe UI Emoji", "Segoe UI Symbol";
 
}




* {    
  margin: 0 ;
  padding: 0 ;
}

body {
  margin: 0;
  font-family: 'Roboto';
  background-color: #ffffff;
  box-sizing:border-box;
  overflow-x: hidden;
  position: relative;
  font-family: -apple-system, "BlinkMacSystemFont", "Segoe UI", "Helvetica", "Apple Color Emoji", "Arial", sans-serif, "Segoe UI Emoji", "Segoe UI Symbol";
 
  
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: var(--ta-primary-color) white;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

*::-webkit-scrollbar-track {
  background: white;
}

*::-webkit-scrollbar-thumb {
  background-color: lightgrey;
  border-radius: 10px;
  border: 0px solid white;
}